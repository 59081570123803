<template>
  <div>
    <header-slot no-border-bottom> </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'correspondence-letters-to-clients-pending' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="inSubTab(1)"
      >
        PENDING
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'correspondence-letters-to-clients-sent' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="inSubTab(2)"
      >
        SENT
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
  methods: {
    inSubTab(type) {
      let routes = [];
      if (type == 1) {
        routes = [
          "correspondence-letters-to-clients-sub-pending",
          "correspondence-letters-to-leads-sub-pending",
        ];
      } else {
        routes = [
          "correspondence-letters-to-clients-sub-sent",
          "correspondence-letters-to-leads-sub-sent",
        ];
      }
      return routes.includes(this.$route.name);
    },
  },
};
</script>

<style scoped>
</style>